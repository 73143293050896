export const maorongTableProperties1 = [
  {
    label: '客户简称',
    prop: 'cust_abbr',
    otherProp: 'custAbbrList',
    options: [],
    itemType: 'select',
    multiple: true,
    collapseTags: true,
    input: true,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '打样台面',
    prop: 'stff_name',
    otherProp: 'stffNameList',
    options: [],
    itemType: 'select',
    multiple: true,
    collapseTags: true,
    input: true,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '委托人',
    prop: 'requ_stff_name',
    otherProp: 'requStffNameList',
    options: [],
    itemType: 'select',
    multiple: true,
    collapseTags: true,
    input: true,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '委托部门',
    prop: 'dept_name',
    otherProp: 'deptNameList',
    options: [],
    itemType: 'select',
    multiple: true,
    collapseTags: true,
    input: true,
    sortable: false,
    labelWidth: 180
  }
];
export const maorongTableProperties2 = [];
export const maorongTableProperties3 = [
  {
    label: '新款总数',
    prop: 'sum_new_product',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 120
  },
  {
    label: '修改款总数',
    prop: 'sum_modified_product',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 120
  },
  {
    label: '老款总数',
    prop: 'sum_old_product',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 120
  },
  {
    label: '总款数',
    prop: 'sum_fequ_smpl_num',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 120
  },
  {
    label: '合计：个',
    prop: 'sum_product',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 120
  }
];
export const feimaorongTableProperties = [
  {
    label: '公司抬头',
    prop: 'cptt_name',
    otherProp: 'cpttNameList',
    options: [],
    itemType: 'select',
    multiple: true,
    collapseTags: true,
    input: true,
    sortable: false,
    labelWidth: 110
  },
  {
    label: '客户简称',
    prop: 'cust_abbr',
    otherProp: 'custAbbrList',
    options: [],
    itemType: 'select',
    multiple: true,
    collapseTags: true,
    input: true,
    sortable: false,
    labelWidth: 110
  },
  {
    label: '采购人员',
    prop: 'stff_name',
    otherProp: 'stffNameList',
    options: [],
    itemType: 'select',
    multiple: true,
    collapseTags: true,
    input: true,
    sortable: false,
    labelWidth: 110
  },
  {
    label: '委托人',
    prop: 'requ_stff_name',
    otherProp: 'requStffNameList',
    options: [],
    itemType: 'select',
    multiple: true,
    collapseTags: true,
    input: true,
    sortable: false,
    labelWidth: 110
  },
  {
    label: '委托部门',
    prop: 'dept_name',
    otherProp: 'deptNameList',
    options: [],
    itemType: 'select',
    multiple: true,
    collapseTags: true,
    input: true,
    sortable: false,
    labelWidth: 110
  },
  {
    label: '年份',
    prop: 'year',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 60
  },
  {
    label: '月份',
    prop: 'month',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 60
  },
  {
    label: '新款-新厂',
    prop: '新款-新厂',
    secondaryHeader: [
      {
        label: '款数',
        prop: 'new_factory_new_product',
        itemType: 'input',
        input: false,
        sortable: false,
        labelWidth: 50
      },
      {
        label: '数量',
        prop: 'new_factory_sum_new_product',
        itemType: 'input',
        input: false,
        sortable: false,
        labelWidth: 50
      }
    ]
  },
  {
    label: '老款-新厂',
    prop: '老款-新厂',
    secondaryHeader: [
      {
        label: '款数',
        prop: 'new_factory_old_product',
        itemType: 'input',
        input: false,
        sortable: false,
        labelWidth: 50
      },
      {
        label: '数量',
        prop: 'new_factory_sum_old_product',
        itemType: 'input',
        input: false,
        sortable: false,
        labelWidth: 50
      }
    ]
  },
  {
    label: '新款-老厂',
    prop: '新款-老厂',
    secondaryHeader: [
      {
        label: '款数',
        prop: 'old_factory_new_product',
        itemType: 'input',
        input: false,
        sortable: false,
        labelWidth: 50
      },
      {
        label: '数量',
        prop: 'old_factory_sum_new_product',
        itemType: 'input',
        input: false,
        sortable: false,
        labelWidth: 50
      }
    ]
  },
  {
    label: '老款-老厂',
    prop: '老款-老厂',
    secondaryHeader: [
      {
        label: '款数',
        prop: 'old_factory_old_product',
        itemType: 'input',
        input: false,
        sortable: false,
        labelWidth: 50
      },
      {
        label: '数量',
        prop: 'old_factory_sum_old_product',
        itemType: 'input',
        input: false,
        sortable: false,
        labelWidth: 50
      }
    ]
  },
  {
    label: '改款-老厂',
    prop: '改款-老厂',
    secondaryHeader: [
      {
        label: '款数',
        prop: 'old_factory_modified_product',
        itemType: 'input',
        input: false,
        sortable: false,
        labelWidth: 50
      },
      {
        label: '数量',
        prop: 'old_factory_sum_modified_product',
        itemType: 'input',
        input: false,
        sortable: false,
        labelWidth: 50
      }
    ]
  },
  {
    label: '改款-新厂',
    prop: '改款-新厂',
    secondaryHeader: [
      {
        label: '款数',
        prop: 'new_factory_modified_product',
        itemType: 'input',
        input: false,
        sortable: false,
        labelWidth: 50
      },
      {
        label: '数量',
        prop: 'new_factory_sum_modified_product',
        itemType: 'input',
        input: false,
        sortable: false,
        labelWidth: 50
      }
    ]
  },
  {
    label: '其他',
    prop: '其他',
    secondaryHeader: [
      {
        label: '款数',
        prop: 'other_product',
        itemType: 'input',
        input: false,
        sortable: false,
        labelWidth: 50
      },
      {
        label: '数量',
        prop: 'other_sum_product',
        itemType: 'input',
        input: false,
        sortable: false,
        labelWidth: 50
      }
    ]
  },
  {
    label: '报价',
    prop: '报价',
    secondaryHeader: [
      {
        label: '款数',
        prop: 'offer_product',
        itemType: 'input',
        input: false,
        sortable: false,
        labelWidth: 50
      },
      {
        label: '数量',
        prop: 'offer_sum_product',
        itemType: 'input',
        input: false,
        sortable: false,
        labelWidth: 50
      }
    ]
  }
];
