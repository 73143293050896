<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-date-picker
          size="small"
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          v-model="dateRange"
          type="daterange"
          range-separator="至"
          start-placeholder="提交开始日期"
          end-placeholder="提交结束日期"
          value-format="timestamp"
        />
        <el-button type="primary" size="small" class="vd_export" @click="getDataList()">查询</el-button>
        <!--<el-date-picker size="small" class="vg_ml_8 vg_mr_8 topDateRangeSearch" v-model="dateRange" type="year" placeholder="统计年份" />-->
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()" icon="el-icon-refresh-right"> 刷新 </el-button>
        <el-button type="success" size="small" class="vd_export" @click="exportExcel()"> 导出Excel </el-button>
      </div>
      <el-radio-group size="small" v-model="pageName" @change="tabChange">
        <el-radio-button label="毛绒">一部</el-radio-button>
        <el-radio-button label="非毛绒">二部</el-radio-button>
        <el-radio-button label="包材">辅料部</el-radio-button>
      </el-radio-group>
      <DynamicUTable
        v-show="pageName === '毛绒'"
        ref="maorong"
        :tableData="tableData"
        :columns="maorongTableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :page-size="50"
        :need-fixed-height="true"
        :need-search="true"
        :need-check-box="false"
        @getTableData="getDataList"
        @changeTableMaxHeight="changeTableMaxHeight"
        :needPageSizes="true"
      >
      </DynamicUTable>
      <DynamicUTable
        v-show="pageName === '非毛绒'"
        ref="feimaorong"
        :tableData="tableData"
        :columns="feimaorongTableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :page-size="50"
        :need-fixed-height="true"
        :need-search="true"
        :need-check-box="false"
        @getTableData="getDataList"
        :show-summary="true"
        :summariesColumns="[
          'new_factory_new_product',
          'new_factory_sum_new_product',
          'new_factory_old_product',
          'new_factory_sum_old_product',
          'new_factory_modified_product',
          'new_factory_sum_modified_product',
          'old_factory_new_product',
          'old_factory_sum_new_product',
          'old_factory_old_product',
          'old_factory_sum_old_product',
          'old_factory_modified_product',
          'old_factory_sum_modified_product',
          'offer_product',
          'offer_sum_product',
          'other_product',
          'other_sum_product'
        ]"
        :needAllColumnSummaryToFixed="0"
        :needPageSizes="true"
      >
      </DynamicUTable>
      <DynamicUTable
        v-show="pageName === '包材'"
        ref="baocai"
        :tableData="tableData"
        :columns="baocaiTableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :page-size="50"
        :need-fixed-height="true"
        :need-search="true"
        :need-check-box="false"
        @getTableData="getDataList"
        :show-summary="true"
        :summariesColumns="[
          'new_factory_new_product',
          'new_factory_sum_new_product',
          'new_factory_old_product',
          'new_factory_sum_old_product',
          'new_factory_modified_product',
          'new_factory_sum_modified_product',
          'old_factory_new_product',
          'old_factory_sum_new_product',
          'old_factory_old_product',
          'old_factory_sum_old_product',
          'old_factory_modified_product',
          'old_factory_sum_modified_product',
          'offer_product',
          'offer_sum_product',
          'other_product',
          'other_sum_product'
        ]"
        :needAllColumnSummaryToFixed="0"
        :needPageSizes="true"
      >
      </DynamicUTable>
    </el-card>
  </div>
</template>

<script>
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { cloneDeep } from 'lodash';
import { keepNumber } from '@assets/js/regExUtil';
import {
  feimaorongTableProperties,
  maorongTableProperties1,
  maorongTableProperties2,
  maorongTableProperties3
} from '@/views/StatisticsManagement/CCPStatistics/ccps';
import { downloadFile, getDataCenterByPermId } from '@api/public';
import { fequAPI } from '@api/modules/fequ';

export default {
  name: 'CCPSStatistics',
  components: {
    DynamicUTable
  },
  data() {
    return {
      dateRange: [],
      m1: cloneDeep(maorongTableProperties1),
      m2: cloneDeep(maorongTableProperties2),
      m3: cloneDeep(maorongTableProperties3),
      feimaorongTableProperties: cloneDeep(feimaorongTableProperties),
      baocaiTableProperties: cloneDeep(feimaorongTableProperties),
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: false,
      pageName: '毛绒'
    };
  },
  computed: {
    maorongTableProperties() {
      return this.m1.concat(this.m2).concat(this.m3);
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    keepNumber,
    initData() {
      this.changeProperties();
    },
    async changeProperties() {
      (await getDataCenterByPermId({ id: 10004 })).forEach(item => {
        this.m2.push({
          label: item.param1,
          prop: item.param2,
          secondaryHeader: [
            {
              label: '新款',
              prop: item.param2 + '-new_product',
              itemType: 'input',
              input: false,
              sortable: false,
              labelWidth: 120
            },
            {
              label: '修改款',
              prop: item.param2 + '-modified_product',
              itemType: 'input',
              input: false,
              sortable: false,
              labelWidth: 120
            },
            {
              label: '老款',
              prop: item.param2 + '-old_product',
              itemType: 'input',
              input: false,
              sortable: false,
              labelWidth: 120
            },
            {
              label: '打样数量',
              prop: item.param2 + '-fequ_smpl_num',
              itemType: 'input',
              input: false,
              sortable: false,
              labelWidth: 120
            }
          ]
        });
      });
      setTimeout(() => {
        this.getDataList();
      }, 500);
    },
    //获取数据
    async getDataList() {
      if (this.pageName === '毛绒') {
        let searchForm = cloneDeep(this.$refs.maorong.searchForm);
        let [startTime, endTime] = this.dateRange || [];
        searchForm.start_sub_time = startTime ? startTime : null;
        searchForm.end_sub_time = endTime ? endTime : null;
        searchForm.custAbbrList = searchForm.custAbbrList?.toString();
        searchForm.stffNameList = searchForm.stffNameList?.toString();
        searchForm.requStffNameList = searchForm.requStffNameList?.toString();
        searchForm.deptNameList = searchForm.deptNameList?.toString();
        await fequAPI.getCustFequStatisticsM(searchForm).then(({ data }) => {
          this.tableData = data.list;
          this.totalPage = data.total;
          this.maorongTableProperties.forEach(item => {
            if (item.otherProp === 'custAbbrList') item.options = data.custAbbrList;
            if (item.otherProp === 'stffNameList') item.options = data.stffNameList;
            if (item.otherProp === 'requStffNameList') item.options = data.requStffNameList;
            if (item.otherProp === 'deptNameList') item.options = data.deptNameList;
          });
        });
      } else {
        let temp = this.pageName === '非毛绒';
        let searchForm = cloneDeep(temp ? this.$refs.feimaorong.searchForm : this.$refs.baocai.searchForm);
        let [startTime, endTime] = this.dateRange || [];
        searchForm.start_sub_time = startTime ? startTime : null;
        searchForm.end_sub_time = endTime ? endTime : null;
        temp ? (searchForm.requ_type = 3) : (searchForm.requ_type = 2);
        searchForm.custAbbrList = searchForm.custAbbrList?.toString();
        searchForm.stffNameList = searchForm.stffNameList?.toString();
        searchForm.requStffNameList = searchForm.requStffNameList?.toString();
        searchForm.deptNameList = searchForm.deptNameList?.toString();
        searchForm.cpttNameList = searchForm.cpttNameList?.toString();
        await fequAPI.getCustFequStatisticsBF(searchForm).then(({ data }) => {
          this.tableData = data.list;
          this.totalPage = data.total;
          this.feimaorongTableProperties.forEach(item => {
            if (item.otherProp === 'custAbbrList') item.options = data.custAbbrList;
            if (item.otherProp === 'stffNameList') item.options = data.stffNameList;
            if (item.otherProp === 'requStffNameList') item.options = data.requStffNameList;
            if (item.otherProp === 'deptNameList') item.options = data.deptNameList;
            if (item.otherProp === 'cpttNameList') item.options = data.cpttNameList;
          });
          this.baocaiTableProperties.forEach(item => {
            if (item.otherProp === 'custAbbrList') item.options = data.custAbbrList;
            if (item.otherProp === 'stffNameList') item.options = data.stffNameList;
            if (item.otherProp === 'requStffNameList') item.options = data.requStffNameList;
            if (item.otherProp === 'deptNameList') item.options = data.deptNameList;
            if (item.otherProp === 'cpttNameList') item.options = data.cpttNameList;
          });
        });
      }
    },
    //跨分页导出excel
    exportExcel() {
      let [startTime, endTime] = this.dateRange || [];
      let start_sub_time = startTime ? startTime : null;
      let end_sub_time = endTime ? endTime : null;
      if (this.pageName === '毛绒') {
        fequAPI.exportCustFequStatisticsM({ type: 1, start_sub_time: start_sub_time, end_sub_time: end_sub_time }).then(({ data }) => {
          downloadFile({ fileUrl: data.url, fileName: data.fileName, type: '' });
        });
      } else {
        fequAPI
          .exportCustFequStatisticsBF({ type: 1, requ_type: this.pageName === '非毛绒' ? 3 : 2, start_sub_time: start_sub_time, end_sub_time: end_sub_time })
          .then(({ data }) => {
            downloadFile({ fileUrl: data.url, fileName: data.fileName, type: '' });
          });
      }
    },
    //刷新
    buttonRefresh() {
      if (this.pageName === '毛绒') {
        this.$refs.maorong.resetFields();
      } else if (this.pageName === '非毛绒') {
        this.$refs.feimaorong.resetFields();
      } else {
        this.$refs.baocai.resetFields();
      }
      this.dateRange = null;
      this.getDataList();
    },
    tabChange(val) {
      if (val === '毛绒') {
        this.$refs.maorong.doLayout();
      } else if (val === '非毛绒') {
        this.$refs.feimaorong.doLayout();
      } else {
        this.$refs.baocai.doLayout();
      }
      this.getDataList();
    },
    changeTableMaxHeight(val) {
      setTimeout(() => {
        this.$refs.feimaorong.tableMaxHeight = val;
        this.$refs.baocai.tableMaxHeight = val;
      });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
